import { Grid, Button, Link, Stack, Text } from '@chakra-ui/react';

const Airdrop = () => {
    return ( 
             <Grid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}} gap="60px">
                <Stack order={{base: '2', md:'1'}} spacing="25px" >
                   
                      <Link href='./airdrop' _hover={{textDecoration: 'none'}} target="_blank">
                        <center><Button colorScheme="yellow">
                           Claiming Soon
                        </Button></center>
                    </Link>
                </Stack>
            </Grid>
    );
};

export default Airdrop;